import { Transition } from "@headlessui/react";
import { Fragment, ReactNode, useContext, useEffect, useState } from "react";
import Button from "components/Button";
import Dropdown from "./Dropdown";
import { countryCodes, useFetch } from "../helpers"
import ImporterPickerWithDropdown from "./ImporterPickerWithDropdown";
import CountrycodeDropdown from "./CountrycodeDropdown";
import { useImportAPI } from "store/import-context";
import AccountContext from "store/account-context";
import { HiX, HiXCircle } from "react-icons/hi";
import { DropdownBody, DropdownItem, Dropdown as NewDropdown } from "dropdown";

const ImporterAlternative = (props: any) => {
    const accountCtx = useContext(AccountContext);
    const [selectedList, setSelectedList] = useState({} as any)
    const [selectedImports, setSelectedImports] = useState([] as any)
    const [importPostData, setImportPostData] = useState({} as any)
    
    let integrationSpecificPostDataInitialState = {} as any;
    if (props.integration.integration_id === "klaviyo") {
        integrationSpecificPostDataInitialState["sync_group"] = true;
    }
    
    const [integrationSpecificPostData, setIntegrationSpecificPostData] = useState(integrationSpecificPostDataInitialState)
    const [nameAlternativeSelected, setNameAlternativeSelected] = useState("")

    const importAPI = useImportAPI();

    const { data, postData, error, loading, getSegments, postImports } = useFetch(props.integration)

    useEffect(() => {
        getSegments()
    }, [])

    useEffect(() => {
        if (!selectedList.name) {
            setSelectedImports([])
        }
    }, [selectedList])

    const selectCountry = (sourceId: string, cc: string) => {
        let newItem = { id: sourceId, cc }
        if (sourceId in importPostData) {
            if (cc !== importPostData[sourceId]['cc']) {
                setImportPostData({ ...importPostData, [sourceId]: newItem })
            } else {
                const newImportPostData = { ...importPostData }
                delete newImportPostData[sourceId]
                setImportPostData(newImportPostData)
            }
        } else {
            setImportPostData({ ...importPostData, [sourceId]: newItem })
        }
    }

    const deselectCountry = (sourceId: string) => {
        let newImportsData = selectedImports.filter((importItem: any) => importItem.id !== sourceId)
        const newImportPostData = { ...importPostData }
        delete newImportPostData[sourceId]
        setImportPostData(newImportPostData)
        setSelectedImports(newImportsData)
    }

    return (
        <div className='flex justify-between min-h-[50rem] mx-auto w-full pl-16 relative'>
            <div className='w-2/6  pt-16 '>
                <div className="min-h-28 h-28 flex items-center">
                    <div className="flex items-center h-12 cursor-pointer"
                        onClick={props.back}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-tamahagane">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                        <p className="ml-2 font-semibold text-base">Back</p>
                    </div>
                </div>
                <div className='flex flex-col w-11/12 justify-start items-center h-full pt-4'>
                    <div className="w-full max-h-[40rem] z-30">
                        <div className="flex flex-col w-[22rem]">
                            <p className="text-base font-semibold mb-2">Choose a list</p>
                            {(loading || error) && <Dropdown disabled={loading} loading={loading} setSelection={(item: any) => undefined} items={[]} headerStyles={"h-14 justify-between bg-white border-granitine rounded-md"} />}
                            {(!loading && data) && <Dropdown setSelection={(dropdownItem: any) => {
                                setSelectedList({ key: dropdownItem.value, name: dropdownItem.title })
                            }} items={data.map((item: any) => ({ id: item.id, title: item.name, value: item.id }))} headerStyles={"h-14 justify-between bg-white border-granitine rounded-md  "} />}
                        </div>

                        <div className="mt-8 flex flex-col w-[22rem]">
                            <p className="text-base font-semibold mb-2">Choose what to import</p>
                            {selectedList.name && (
                                props.integration.integration_id === "mailchimp" ? <ImporterPickerWithDropdown canImportMainList={true} segmentData={data} selected={selectedImports.map((importItem: any) => importItem.name).join(", ")} selectedList={selectedList} selectedImports={selectedImports} setSelectedImports={setSelectedImports} placeholder="Choose segment" headerButtonStyles={"h-14"} />
                                    : <ImporterPickerWithDropdown canImportMainList={false} segmentData={data} selected={selectedImports.map((importItem: any) => importItem.name).join(", ")} selectedList={selectedList} selectedImports={selectedImports} setSelectedImports={setSelectedImports} placeholder="Choose segment" headerButtonStyles={"h-14 justify-between bg-white border-granitine rounded-md  "} />)}

                            {!selectedList.name && <ImporterPickerWithDropdown disabled={true} segmentData={[]} selected={selectedImports.map((importItem: any) => importItem).join(", ")} selectedList={""} selectedImports={[]} setSelectedImports={setSelectedImports} headerButtonStyles={"h-14 justify-between bg-white border-granitine rounded-md  "} />}
                        </div>

                        {props.integration.integration_id === "klaviyo" ?
                            <>
                                <div className="mt-8 w-[22rem]">
                                    <p className="text-base font-semibold mb-2">Name alternative for imports</p>
                                    <NewDropdown
                                        placeholder={"Choose name variant"}
                                        bodyEaseIn={false}
                                        disabled={!selectedList.name}
                                        headerButtonStyles={"h-14 justify-between bg-white border-granitine rounded-md  "}
                                        renderBody={(dropdownSettings: any) =>
                                            <DropdownBody
                                                renderItem={(bodySettings: any) =>
                                                    <DropdownItem
                                                        key={bodySettings.id}
                                                        id={bodySettings.id}
                                                        title={bodySettings.title}
                                                        checkbox={true}
                                                        checkboxChecked={nameAlternativeSelected === bodySettings.title}
                                                        handleClick={() => {
                                                            setIntegrationSpecificPostData((oldVal: any) => ({ ...oldVal, contact_names: bodySettings.value }))
                                                            setNameAlternativeSelected((oldVal) => oldVal === bodySettings.title ? "" : bodySettings.title)
                                                            dropdownSettings.handleClose()
                                                        }} />
                                                }
                                                data={[{ value: "contact_names-email", title: "Email", id: 1 }, { value: "contact_names-firstname", title: "Förnamn", id: 2 }]}
                                            />
                                        }
                                        selected={nameAlternativeSelected}
                                    />
                                </div>

                                <div className="flex mt-8 pr-4 w-full">
                                    <input type="checkbox" 
                                    checked={!!(integrationSpecificPostData?.sync_group)}
                                    onChange={() => setIntegrationSpecificPostData((oldVal: any) => {
                                        let newVal = {...oldVal};
                                        if ("sync_group" in newVal) {
                                            delete newVal["sync_group"];
                                        } else {
                                            newVal["sync_group"] = true;
                                        }
                                        return newVal;
                                    })} 
                                    className="rounded border-gunMetal25 text-tamahagane focus:ring-tamahagane col-span-1 self-center w-6 h-6 mr-4 cursor-pointer" />
                                    <p className="text-base font-semibold">Sync segment to group continuously</p>
                                </div>
                            </>
                            : null}

                    </div>
                </div>
            </div>
            <div className="border-white border-r-[3px] h-full mx-8 z-10">
            </div>
            <div className='flex flex-col w-4/6 justify-start items-center h-full pt-16 z-10'>
                <div className='flex items-center min-h-28 h-28 '>
                    <p className=' text-lg font-semibold mr-4'>Sync data from</p>
                    <div className='border border-gunMetal25 rounded-lg py-2 px-6 mr-4 bg-white'>
                        <img className='object-contain w-12 h-12 bg-white' src={props.selectedSource.src} alt="" />
                    </div>
                </div>
                <div className="pl-12 pr-8 w-full h-4/6 pt-8">
                    <div className='flex flex-col border border-gunMetal25 bg-white w-full rounded-xl min-h-[30rem] '>
                        {
                            selectedImports.length ?
                                <>
                                    {
                                        selectedImports.map((sourceImport: any) => {
                                            return (
                                                <Transition
                                                    as={Fragment}
                                                    appear={true}
                                                    enter="duration-[500ms] transition-all ease-out"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    key={sourceImport.id}
                                                >
                                                    <div className="flex py-4 items-center justify-between border-b border-black/20" >
                                                        <div className="pl-16 pr-8 flex w-full items-center">

                                                        <div className="flex w-2/3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-decoGray">
                                                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                                                                </svg>
                                                            <p className="ml-4 text-base truncate font-semibold">{sourceImport.name}</p>
                                                        </div>
                                                        <div className="w-56 mr-8">
                                                            <CountrycodeDropdown items={[{country: 'Any (international format)', alpha2: 'none'}].concat(countryCodes).map((item: any, i: number) => ({ id: i, title: item.country, value: item.alpha2 }))} setSelection={(cc: string) => selectCountry(sourceImport.id, cc)} />
                                                        </div>
                                                        <div className="ml-2 cursor-pointer" onClick={() => deselectCountry(sourceImport.id)}>
                                                                <HiXCircle className="w-6 h-6 text-tamahagane" />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            )
                                        })
                                    }
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`mt-4 w-full flex justify-end pr-8`}>
                    <Button text="Confirm" handleClick={() => {
                        const postRes = postImports(selectedImports, importPostData, integrationSpecificPostData)
                        postRes.then((res: any) =>
                            importAPI.pollImports(res.imports, accountCtx.integrations.find((integration: any) => integration.integration_id === props.integration.integration_id))
                        )
                        props.shouldFetch((oldVal: boolean) => !oldVal)
                    }}
                        disabled={((selectedImports.length !== Object.keys(importPostData).length) || (!selectedImports.length))} bgColor="bg-wilmington" textColor="white" style="px-16 h-12 w-56" />
                </div>
            </div>

        </div >
    )
}

export default ImporterAlternative