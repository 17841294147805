import {Fragment, useContext, useEffect, useRef, useState} from "react";
import { Dialog, Transition } from "@headlessui/react";
import klaviyoLogo from 'assets/klaviyo_logo.png'

import ImporterAlternative from "./Importer";
import { classNames } from "helpers";
import ExcelImporter from "./ExcelImporter/ExcelImporter";
import AccountContext from "../../../store/account-context";

const sources = [
    {
        key: "mailchimp",
        src: "https://segint-mailchimp.jojk.me/static/images/Mailchimp_Logo-Vertical_Black.png",
        segmentUrl: "https://segint-mailchimp.jojk.me/segment-options",
    },
    {
        key: "klaviyo",
        src: klaviyoLogo,
        segmentUrl: "https://segint-klaviyo.jojk.me/segment-options",
    },
    {
        key: "excel",
        src: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/1200px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png",
        segmentUrl: "https://segint-mailchimp.jojk.me/segment-options",
    },
    {
        key: "emarsys",
        src: "https://segint-emarsys.jojk.me/static/images/ema_logo_2018_stacked_color_RGB.png",
        segmentUrl: "https://segint-emarsys.jojk.me/segment-options",
    },
    {
        key: "shopify",
        src: "https://segint-dummy.jojk.me/shopify/shopify_logo_black.png",
        segmentUrl: "https://segint-shopify.jojk.me/segment-options",
    },
    {
        key: "infobaleen",
        src: "https://segint-dummy.jojk.me/infobaleen/Infobaleen_Logo.png",
        segmentUrl: "https://segint-infobaleen.jojk.me/segment-options",
    },
];

const CreateModal = (props: any) => {
    const [showSource, setShowSource] = useState(true);
    const [selectedSource, setSelectedSource] = useState({ key: "", src: "" });
    const accountCtx = useContext(AccountContext);
    const activeIntegrations = accountCtx.integrations.map(x => x.integration_id as string);  
    
    useEffect(() => {
        if (props.open) {
            setShowSource(true);
        }
    }, [props.open]);

    const integrations = [
        ...props.integrations,
        {
            integration_id: "mailchimp2",
            key: "42a9f1bf26ffae91a61be3396bcbaf0abd13c88f8c6645c3",
        },
        {
            integration_id: "mailchimp3",
            key: "42a9f1bf26ffae91a61be3396bcbaf0abd13c88f8c6645c3",
        },
    ]; // Only used because of alternative mailchimps
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10 min-h-full w-full bg-slate-500"
                onClose={() => {
                    props.setOpen(false);
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 ">
                    <div className="absolute top-0 left-0 h-screen w-screen opacity-50 "></div>
                    <div className="flex min-h-full w-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div>
                                <Dialog.Panel className="relative transform rounded-lg bg-brightWhite md:px-8  text-left shadow-xl transition-all h-[55rem] overflow-y-scroll 2xl:w-[90rem] 2xl:min-w-[80rem] md:w-[90rem] mx-auto border-4 border-white">
                                    <div className="min-h-full mx-auto w-full relative">
                                        <Transition.Root
                                            show={showSource}
                                            enterFrom="opacity-0 -translate-x-10"
                                            enterTo="opacity-100 translate-x-0"
                                            enter="transition-all duration-[700ms] absolute -bottom-0 ease-out"
                                            leave="transition-opacity ease-out"
                                            leaveFrom="opacity-100 translate-x-0"
                                            leaveTo="opacity-0 "
                                            className={"min-h-full w-full"}
                                        >
                                            <div className="flex flex-col items-center min-h-full mx-auto w-full pt-12">
                                                <p className="text-lg font-semibold">
                                                    Select source
                                                </p>
                                                <div className="flex mt-8 items-center w-full">
                                                    <div className="bg-white flex items-center w-[16rem] min-h-full border border-gunMetal25 rounded-default pr-3">
                                                        <input
                                                            type="text"
                                                            className="border-0 focus:ring-0 apperance-none outline-0 text-base py-2 px-3 w-full rounded-default h-12"
                                                        />
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth={2.3}
                                                            stroke="currentColor"
                                                            className="w-8 h-8"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <p className="text-base font-semibold ml-4">
                                                        Get more out of your
                                                        segmentation by
                                                        synchronizing multiple
                                                        data sources directly
                                                        into Bogio
                                                    </p>
                                                </div>
                                                <div className="mt-8 border-white border-b-[3px] w-full"></div>
                                                <Transition.Child
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0"
                                                    enterTo="opacity-100"
                                                    leave="ease-in transition-translate duration-300"
                                                    leaveFrom="translate-x-0"
                                                    leaveTo="-translate-x-28"
                                                    className={"min-h-full w-full"}
                                                >
                                                    <div className="flex flex-wrap justify-between mt-8 w-full">
                                                        {sources.map(
                                                            (source: any) => {
                                                                return (
                                                                    <div className="flex flex-col items-center">
                                                                        <div
                                                                            onClick={() => {
                                                                                if (source.key === "excel" || activeIntegrations.includes(source.key)) {
                                                                                    setShowSource(false);
                                                                                    setSelectedSource(source);
                                                                                }
                                                                            }}
                                                                            className={classNames(
                                                                                "w-40 h-40 hover:w-44 hover:h-44 transition-all duration-150 cursor-pointer p-4 border border-gray col-span-1 flex flex-col justify-center items-center rounded-lg",
                                                                                source.key !== "excel" && !activeIntegrations.includes(source.key)
                                                                                    ? "grayscale bg-white"
                                                                                    : "bg-white"
                                                                            )}
                                                                            key={
                                                                                source.key
                                                                            }
                                                                        >
                                                                            <img
                                                                                className={classNames(
                                                                                    "object-contain p-4 ",
                                                                                    source.key !== "excel" && !activeIntegrations.includes(source.key) &&
                                                                                        "peer h-full w-full"
                                                                                )}
                                                                                src={
                                                                                    source.src
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        {![
                                                                            "mailchimp",
                                                                            "klaviyo",
                                                                            "excel",
                                                                        ].includes(
                                                                            source.key
                                                                        ) ? (
                                                                            <p className="text-base peer-hover:visible font-semibold text-gunMetal/50">
                                                                                Coming
                                                                                soon
                                                                            </p>
                                                                        ) : undefined}
                                                                        {[
                                                                            "mailchimp",
                                                                            "klaviyo",
                                                                        ].includes(
                                                                            source.key
                                                                        ) && !activeIntegrations.includes(source.key)? (
                                                                            <p className="text-base peer-hover:visible font-semibold text-gunMetal/50">
                                                                                Not activated
                                                                            </p>
                                                                        ) : undefined}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </Transition.Child>
                                            </div>
                                        </Transition.Root>
                                        <Transition
                                            enterFrom="opacity-0 translate-x-44"
                                            enterTo="opacity-100 translate-x-0"
                                            enter="transition-all duration-[700ms] absolute -bottom-0 ease-out"
                                            leave="transition-all"
                                            leaveTo="opacity-0"
                                            leaveFrom="opacity-100"
                                            show={!showSource}
                                            className="min-h-full w-full"
                                        >
                                            {selectedSource.key === "excel" ? (
                                                <ExcelImporter handleClose={() => props.setOpen(false)} back={() => setShowSource(true)} integration={integrations.find((item: any) => item.integration_id === selectedSource.key)} selectedSource={selectedSource} />
                                            ) : (
                                                <ImporterAlternative
                                                    shouldFetch={() => {
                                                        props.setOpen(false);
                                                        props.shouldFetch(
                                                            (oldVal: boolean) =>
                                                                !oldVal
                                                        );
                                                    }}
                                                    integration={
                                                        integrations.filter(
                                                            (item: any) =>
                                                                item.integration_id ===
                                                                selectedSource.key
                                                        )[0]
                                                    }
                                                    selectedSource={
                                                        selectedSource
                                                    }
                                                    back={() =>
                                                        setShowSource(true)
                                                    }
                                                />
                                            )}
                                        </Transition>
                                    </div>
                                </Dialog.Panel>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default CreateModal;