import Button from "components/Button";
import { DotsLoader } from "components/DotsLoader";
import { getCookie, useGetData, useInterval } from "helpers";
import { useRef } from "react";
import { useGroupAPI } from "store/group-context";
import { ImportingViewProps } from "./types";


const ImportingView = (props: ImportingViewProps) => {
    const { getData, isLoading, error, data } = useGetData();

    const groupAPICtx = useGroupAPI();
    const pollingDone = useRef<boolean>(false);

    useInterval(() => {
        getData(
            `${process.env.REACT_APP_API_BASE}/uploads/${props.uploadData.number}`,
            (fetchedData) => {
                if (fetchedData.upload.state === "completed") {
                    pollingDone.current = true;
                }
            }
        );
        return !pollingDone.current;
    }, 1000);

    const handleFinish = () => {
        const isUploadCompleted = data?.upload.state === "completed";
        const isValidEntries = data?.upload.entries?.valid;

        if (isUploadCompleted && isValidEntries) {
            const token = getCookie("token");
            groupAPICtx.fetchGroups(token);
            props.handleClose()
        } else {
            props.handleClose()
        }

    };

    return (
        <div className="flex flex-col justify-start items-center h-full pt-16 w-4/6 mx-auto">
            <div className="flex items-center min-h-28 h-28 ">
                <p className=" text-lg font-semibold mr-4">Sync data from</p>
                <div className="border border-gunMetal25 rounded-lg py-2 px-6 mr-4 bg-white">
                    <img
                        className="object-contain w-12 h-12 bg-white"
                        src={props.selectedSource.src}
                        alt=""
                    />
                </div>
            </div>
            <p className="text-base font-semibold mb-2">
                Importing new group: {props.uploadData.name}
            </p>
            {data ? (
                !["completed", "failed"].includes(data.upload.state) ? (
                    <div className="bg-white border border-gunMetal/20 rounded-default w-full p-8 flex justify-center gap-2 items-center">
                        <DotsLoader />
                    </div>
                ) : undefined
            ) : undefined}
            {data ? (
                data.upload.state === "completed" ? (
                    <div className="bg-white border border-gunMetal/20 rounded-default w-full p-8 flex flex-col gap-2 justify-center">
                        <p className="text-base font-semibold">
                            Completed import
                        </p>
                        <p className="text-base">
                            Total entries: {data.upload.entries.total}
                        </p>
                        <p className="text-base">
                            Invalid numbers: {data.upload.entries.bad_number}
                        </p>
                        <p className="text-base">
                            Empty rows: {data.upload.entries.empty_row}
                        </p>
                        <p className="text-base">
                            Contacts in blocklist:{" "}
                            {data.upload.entries.blocklist}
                        </p>
                        <p className="text-base font-semibold mt-4">
                            Failed imports: {data.upload.entries.invalid}
                        </p>
                        <p className="text-base font-semibold">
                            Successful imports: {data.upload.entries.valid}
                        </p>
                    </div>
                ) : undefined
            ) : undefined}
            {data ? (
                data.upload.state === "failed" ? (
                    <div className="bg-white border border-gunMetal/20 rounded-default w-full p-8 flex flex-col gap-2 justify-center">
                        <p className="text-base font-semibold">Import failed</p>
                        <p className="text-base">
                            Import failed for unkown reason.
                        </p>
                    </div>
                ) : undefined
            ) : undefined}
            {!data ? (
                <div className="bg-white border border-gunMetal/20 rounded-default w-full p-8 flex justify-center gap-2 items-center">
                    <DotsLoader />
                </div>
            ) : undefined}
            <div className={`absolute bottom-20 z-30 right-8 `}>
                <Button
                    text="Finish"
                    handleClick={handleFinish}
                    bgColor="bg-wilmington"
                    textColor="white"
                    style="h-12"
                />
            </div>
        </div>
    );
};

export default ImportingView;
